<template>
  <div class="userView content-index" v-loading="tableLoading">
    <div class="topView">
      <div class="contentView">
        <el-page-header
          style="padding:10px"
              @back="$router.go(-1)"
              content="用户列表"
          ></el-page-header>
        <div class="filterView">
          <div class="dateView">
            <el-date-picker
                v-model="dateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                @change="changeDateRange"
            >
            </el-date-picker>
          </div>
          <div class="orderTypeView">
            <el-select v-model="userType" placeholder="用户类型" clearable>
              <el-option
                  v-for="(item, index) in userTypeOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.val"
              >
              </el-option>
            </el-select>
          </div>
          <div class="orderTypeView">
            <el-select v-model="balanceType" placeholder="余额类型" clearable>
              <el-option
                  v-for="(item, index) in balanceTypeOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.val"
              >
              </el-option>
            </el-select>
          </div>
          <div class="orderTypeView">
            <el-select v-model="userStatus" clearable placeholder="用户状态">
              <el-option
                  v-for="(item, index) in userStatusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.val"
              >
              </el-option>
            </el-select>
          </div>
          <div class="keywordView">
            <el-input
                v-model="queryContent"
                clearable
                placeholder="请输入用户手机号"
            ></el-input>
          </div>
          <div class="keywordView">
            <el-button type="success" @click="handleFind">确 认</el-button>
            <el-button type="success" @click="rest">重 置</el-button>
          </div>
        </div>
      </div>
    </div>
    <div style="margin:10px 0 10px">
      用户数量：{{ userData.total }}人<span style="margin-left:30px"
    >本店通享卡余额：{{ userData.totalUniversalBalance }}</span
    ><span style="margin-left:30px"
    >本店历史余额：{{ userData.totalHistoryBalance }}</span
    >
    </div>
    <div class="contentView">
      <div class="headerView flexView">
        <label>序号</label>
        <label>顾客姓名</label>
        <label>电话</label>
        <label>顾客类型</label>
        <label>权益到期日</label>
        <label>通享卡余额（元）</label>
        <label>本店历史余额（元）</label>
        <label>顾客状态</label>
        <!-- <label>积分</label> -->
        <label>消费频次</label>
        <!-- <label>是否充值</label> -->
      </div>
      <!-- <div
        class="listView"
        style="height: calc(100vh - 300px)"
        v-infinite-scroll="loadUserArr"
        infinite-scroll-distance="10"
        infinite-scroll-disabled="disableLoadUserArr"
      > -->
      <div class="listView">
        <div
            class="listItem flexView"
            v-for="(item, index) in userData.userList"
            :key="index"
        >
          <label>{{ (currentPage - 1) * 10 + index + 1 }}</label>
          <label>{{ item.realName }}</label>
          <label>{{ item.phone }}</label>
          <label v-if="item.userType === 1">散客</label>
          <label v-if="item.userType === 2">权益会员</label>
          <label v-if="item.userType === 3">充值会员</label>
          <label>{{ item.expiredTime }}</label>
          <label>{{ item.universalBalance }}</label>
          <label>{{ item.historyBalance }}</label>
          <label v-if="item.state === 0">正常</label>
          <label v-else-if="item.state === 1">禁用</label>
          <label v-else-if="item.state === 2">已注销</label>
          <!-- <label>{{ item.score }}</label> -->
          <label v-if="item.consumptionFrequency === 0">未消费</label>
          <label v-else>{{ item.consumptionFrequency }} 次</label>
          <!-- <label>{{ item.rechargeFlag == 1 ? "未充值" : "充值" }}</label> -->
          <button class="btn-edit" @click="handleUserDetail(item.userId)">
            查看
          </button>
        </div>
        <div
            v-if="userData.userList && userData.userList.length == 0"
            class="moreItem"
        >
          没有更多用户哦
        </div>
        <div
            class="page-box">
          <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <transition name="userDetails">
      <div class="memberDetailView" v-if="q">
        <div class="topView">
          <div class="backView">
            <el-button
                class="btn-back"
                icon="el-icon-arrow-left"
                @click="q = false"
            >返回
            </el-button
            >
          </div>
          <div class="contentView">
            <div class="tView">用户详情</div>
            <div class="menuView">
              <div
                  v-for="(tab, index) in tabs"
                  class="menuItem"
                  :key="index"
                  @click="toggleTab(tab.id)"
              >
                <label :class="{ select: currentTab === tab.id }">{{
                    tab.title
                  }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="contentView">
          <!--用户信息-->
          <member-base-info
              :user-id="userId"
              v-if="currentTab === 0"
          ></member-base-info>
          <!--交易记录-->
          <!--          <div class="orderListView" v-if="currentTab === 1">-->
          <transaction-table
              v-if="currentTab === 1"
              :user-id="userId"
          ></transaction-table>
          <!--          </div>-->
          <recharge-record
              v-if="currentTab === 2"
              :user-id="userId"
          ></recharge-record>
          <!--优惠券-->
          <coupon-record
              v-if="currentTab === 4"
              :user-id="userId"
          ></coupon-record>
          <!-- 转余额记录 -->
          <transferRecord v-if="currentTab === 5" :user-id="userId"/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {getUserPage} from "@/api/user";
import {dateFormat} from "@/utils/date";
import RechargeRecord from "@/components/memberInfo/rechargeRecord";
import MemberBaseInfo from '@/components/memberInfo/memberBaseInfo'
import couponRecord from "@/components/memberInfo/couponRecord";
import transactionTable from "@/components/memberInfo/transactionTable";
import transferRecord from "@/components/memberInfo/transferRecord";

export default {
  name: "index",
  components: {
    RechargeRecord,
    MemberBaseInfo,
    couponRecord,
    transactionTable,
    transferRecord,
  },
  props: {},
  data() {
    return {
      tabs: [
        {id: 0, title: "基本信息"},
        {id: 1, title: "交易记录"},
        {id: 2, title: "充值记录"},
        {id: 3, title: "积分记录"},
        {id: 4, title: "优惠券"},
        {id: 5, title: "转余额记录"},
      ],
      currentTab: 0,
      q: false,
      memberBaseInfoView: true,
      orderListView: false,
      outFactory: false,
      reWash: false,
      timeOut: false,
      userTypeOptions: [
        {
          label: "散客",
          val: "1",
        },
        {
          label: "权益会员",
          val: "2",
        },
        {
          label: "充值会员",
          val: "3",
        },
      ],
      timeStart: this.getMonth("s", 0),
      timeEnd: this.getMonth("e", 0),
      userStatus: "",
      queryContent: "",
      userType: "",
      userStatusOptions: [
        {
          label: "正常",
          val: "0",
        },
        {
          label: "禁用",
          val: "1",
        },
        {
          label: "注销",
          val: "2",
        },
      ],
      balanceType: "",
      balanceTypeOptions: [
        {
          label: "历史余额",
          val: "0",
        },
        {
          label: "通享卡余额",
          val: "1",
        },
      ],
      dateRange: [],
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总数量
      selectIndex: null, //搜索结果,被选中的li index
      userDetail: {
        userId: "",
        userType: 1,
        phone: "",
        birthday: "",
        realName: "",
        userName: "",
        avator: "",
        email: "",
        score: 0,
        totalScore: 0,
        state: 0,
        consumptionFrequency: 0,
        userQuality: 0,
        createTime: "",
        memberUser: {
          cardNo: "",
          balance: 0,
        },
        totalOrderNum: 0,
        totalConsumption: 0,
        avgOrderPrice: 0,
        recordList: [],
        orderList: [],
      },
      userId: "",
      userData: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      tableLoading: false,
    };
  },
  // computed: {
  //   disableLoadUserArr() {
  //     return this.loadingUserArr
  //   },
  // },
  watch: {
    // 每次切换tab都会执行一次
    currentTab(newVal, oldVal) {
      console.log(newVal, oldVal);
      // 基本信息
    },
  },
  created() {
  },
  mounted() {
    this.dateRange = [this.getMonth("s", 0), this.getMonth("e", 0)];
    this.handleFind();
  },
  methods: {
    // 获取本月的时间
    getMonth(type, months) {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var dayDate = d.getDate();
      if (Math.abs(months) > 12) {
        months = months % 12;
      }
      if (months != 0) {
        if (month + months > 12) {
          year++;
          month = (month + months) % 12;
        } else if (month + months < 1) {
          year--;
          month = 12 + month + months;
        } else {
          month = month + months;
        }
      }
      month = month < 10 ? "0" + month : month;
      var firstday = year + "-" + month + "-" + "01";
      var lastday = year + "-" + month + "-" + dayDate;
      var day = "";
      if (type == "s") {
        day = firstday;
      } else {
        day = lastday;
      }
      return day;
    },
    toggleTab: function (index) {
      this.currentTab = index;
    },
    handleDateFind(time) {
      if (time === 0) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(time);
      }
      if (time === -1) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(time);
      }
      if (time === -7) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(0);
      }
      if (time === -30) {
        this.timeStart = this.GetDateStr(time);
        this.timeEnd = this.GetDateStr(0);
      }
    },
    handleFind: function () {
      this.currentPage = 1;
      this.pageSize = 10;
      this.loadUserArr();
    },
    loadUserArr() {
      let _this = this;
      // this.loadingUserArr = true;
      // // 在这里请求api，获取数据后 push给userArr
      // console.log(_this.currentPage)
      let params = {};
      if (this.queryContent) {
        params["queryContent"] = this.queryContent;
      } else {
        params["current"] = this.currentPage;
        params["size"] = this.pageSize;
        if (this.dateRange) {
          params.beginTime = this.dateRange[0];
          params.endTime = this.dateRange[1];
        }
        if (this.userType !== "0") {
          params["userType"] = this.userType;
        }
        params["balanceType"] = this.balanceType;
        params["userStatus"] = this.userStatus;
      }
      this.tableLoading = true;
      getUserPage(params)
          .then(({data}) => {
            console.log(data);
            if (data.code == 0) {
              this.tableLoading = false;
              _this.total = data.data.total;
              _this.userData = data.data;
            } else {
              this.tableLoading = false;
            }
          })
          .catch(() => {
            this.tableLoading = false;
          });
    },

    handleUserDetail(userId) {
      this.q = true;
      this.userId = userId;
    },
    changeDateRange(val) {
      if (!val) {
        this.dateRange = [];
      } else {
        this.dateRange = val;
      }
    },
    // 修改分页触发
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadUserArr();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.loadUserArr();
    },
    // 重置搜索条件
    rest() {
      this.pageSize = 10;
      this.currentPage = 1;
      this.dateRange = [this.getMonth("s", 0), this.getMonth("e", 0)];
      this.queryContent = "";
      this.userType = "";
      this.balanceType = "";
      this.userStatus = "";
      this.loadUserArr()
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  float: right;
  margin-top: 15px;
}

.userView {
  padding: 20px;
  overflow: auto;

  > .topView {
    background: #fff;

    > .contentView {
      /*display: flex;*/
      position: relative;
      padding: 0 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 35px;
        width: 120px;
        font-family: PingFangSC-Semibold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .filterView {
        display: flex;
        height: 44px;
        padding: 15px 0 0 0;

        > div {
          height: 44px;
          margin-right: 15px;
        }

        > .keywordView {
          display: flex;
          flex-wrap: nowrap;

          > button {
            background: #3370ff;
            font-size: 16px;
            font-family: PingFangSC-Medium;
            color: #fff;
            height: 40px;
          }
        }
      }
    }
  }

  > .contentView {
    text-align: center;

    > .headerView {
      /*padding-left: 50px;*/
      /*line-height: 50px;*/
      /*height: 50px;*/
      /*display: flex;*/
      /*font-size: 14px;*/
      /*color: #999;*/
      line-height: 40px;
      padding: 0 140px 0 25px;
      font-size: 13px;
      color: #666;
      background-color: rgba(246, 247, 248, 100);
      text-align: center;

      /*> label {*/
      /*    flex: 1;*/
      /*    !* flex: 2; *!*/
      /*}*/
    }

    > .listView {
      height: calc(100vh - 350px);


      > .listItem {
        position: relative;
        font-size: 13px;
        color: #333;
        background: #f8f8f8;
        text-align: center;
        height: 50px;
        line-height: 50px;

        > .btn-edit {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 30px;
          margin: auto;
          width: 65px;
          height: 36px;
          border-radius: 6px;
          background: #3370ff;
          font-size: 14px;
          color: #fff;
        }
      }

      > .moreItem {
        margin-top: 5px;
        line-height: 40px;
        font-size: 13px;
        color: #3370ff;
        text-align: center;
      }

      > .listItem:nth-child(odd) {
        background: #fff;
      }

      .page-box {
        margin: 20px 0;
        text-align: right;
      }
    }
  }

  > .contentView .flexView {
    display: flex;
    padding: 5px 140px 5px 0;

    > label {
      flex: 1;
    }

    > label:nth-child(1) {
      flex: 0.6;
    }

    > label:nth-child(3) {
      flex: 1.2;
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.memberDetailView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  padding: 120px 20px 25px 25px;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 115px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }

      > .menuView {
        flex: 1;
        height: 60px;
        line-height: 40px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        /* padding: 0 100px 0 160px; */
        /*> /deep/ .el-tabs__nav-wrap::after {*/
        /*    position: static !important;*/
        /*}*/

        .menuItem {
          display: inline-block;
          min-width: 60px;
          text-align: center;
          padding: 0 25px;
          cursor: pointer;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #333;

          > .select {
            position: relative;
            color: #3370ff;
          }

          > .select:after {
            content: "";
            left: 0;
            right: 0;
            bottom: -8px;
            position: absolute;
            width: 24px;
            height: 2px;
            background: #3370ff;
            margin: auto;
            border-radius: 1px;
          }
        }
      }

      > .btnView {
        width: 132px;
        display: flex;

        > button {
          flex: 1;
          margin-right: 12px;
          height: 40px;
          background: #47bf7c;
          color: #fff;
          font-size: 14px;
          font-family: PingFangSC-Medium;
          border-radius: 4px;
        }

        > button:last-child {
          margin-right: 0;
          background: #3370ff;
        }
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}

.memberBaseInfoView {
  position: relative;
  height: 100%;
  padding-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;

  > .memberInfoView {
    display: flex;

    > div {
      width: 215px;
      height: 48px;
      margin-right: 10px;
      background: #f8f8f8;
      border-radius: 6px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      display: flex;
      overflow: hidden;

      > label {
        color: #999;
        margin-right: 10px;
      }

      > input {
        flex: 1;
        background: #f8f8f8;
        border: none;
        outline: none;
        text-align: right;
        font-size: 14px;
        color: #333;
        height: 24px;
        min-width: 0;
        padding: 0;
      }

      > input[disabled] {
        color: #333;
        -webkit-text-fill-color: #333;
        opacity: 1;
      }
    }
  }

  > .cardInfoView {
    margin-top: 30px;
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      height: 48px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .accInfoView {
    margin-top: 18px;
    overflow: hidden;

    > div {
      float: left;
      display: flex;
      width: 290px;
      height: 48px;
      padding: 12px;
      line-height: 24px;
      font-size: 14px;
      background: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      margin-bottom: 15px;

      > label:nth-child(1) {
        color: #999;
        margin-right: 10px;
      }

      > label:nth-child(2) {
        flex: 1;
        color: #333;
        text-align: right;
      }
    }
  }

  > .btn-save {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 90px;
    line-height: 40px;
    height: 40px;
    font-size: 15px;
    color: #fff;
    border-radius: 5px;
    background: #3370ff;
    padding: 0;
  }
}

.orderListView {
  position: relative;
  text-align: center;
  padding-top: 10px;

  > .headerView {
    display: flex;
    padding-right: 50px;
    line-height: 44px;
    background: #fff;

    > label {
      flex: 1;
      font-size: 14px;
      color: #333;
    }
  }

  > .listView {
    overflow-x: hidden;
    overflow-y: auto;

    > .listItem {
      position: relative;
      display: flex;
      line-height: 20px;
      padding: 15px 50px 15px 0;
      background: #fff;

      > label {
        flex: 1;
        font-size: 14px;
        color: #333;
      }
    }

    > .listItem:nth-child(odd) {
      background: #f8f8f8;
    }
  }
}

button {
  /*background: transparent;*/
  border: none;
  outline: none;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
